import React from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

function Campos(props) {
  const { campos, data } = props;

  const Input = (props) => {
    return (
      <input
        placeholder={campos.label}
        name={campos.name}
        {...campos}
        {...props}
        defaultValue={data[campos.name]}
      />
    );
  };
  const Select = () => {
    return (
      <select {...campos}>
        <option value="" disabled selected>
          {campos.label}
        </option>
        {campos.opciones.split(",").map((op) => {
          return <option value={op}>{op}</option>;
        })}
      </select>
    );
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: isMobile ? 250 : 400,
        }}
      >
        <div
          style={{ display: "flex", flexGrow: "4", flexDirection: "column" }}
        >
          {campos.type === "checkbox" ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {campos.required && (
                  <strong style={{ color: "red", marginRight: 5 }}>*</strong>
                )}
                <Input />
                {campos.link ? (
                  <a
                    rel="noreferrer"
                    style={{ marginLeft: 5 }}
                    target="_blank"
                    href={campos.link}
                  >
                    {campos.label}
                  </a>
                ) : (
                  <span>{campos.label}</span>
                )}
              </div>
            </>
          ) : (
            <label>
              <span>
                {campos.required && <strong style={{ color: "red" }}>*</strong>}
                {campos.label}
              </span>
              {campos.type === "text" && <Input />}
              {campos.type === "number" && <Input />}
              {campos.type === "password" && <Input minLength={9} />}
              {campos.type === "email" && <Input />}
              {campos.type === "file" && <Input />}
              {campos.type === "select" && <Select />}
            </label>
          )}
        </div>
      </div>
    </>
  );
}

export default Campos;
