import React from "react";
import { Api } from "../config/Api";
import Campos from "./campos";
import ModalVerificarEmail from "./ModalVerificarEmail";

import ClipLoader from "react-spinners/ClipLoader";

const Mensaje = (props) => {
  return (
    props.errorForm && <span className="tag-error">{props.textoError}</span>
  );
};

function Formulario(props) {
  const { campos, textoBoton, params, idFormulario } = props;
  const [errorForm, setErrorForm] = React.useState(false);
  const [textoError, setTextoError] = React.useState("No pudimos registrarte");
  const [enviando, setEnviando] = React.useState(false);
  const [modalConfirmarEmail, setConfirmarEmail] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [data, setData] = React.useState({});

  const Campo = (propi) => {
    return (
      <Campos data={data} key={"ids" + propi.index} campos={propi.campo} />
    );
  };

  function registrarme(event, formu, verificado) {
    try {
      if (event) {
        event.preventDefault();
      }

      var formLleno = {};
      if (formu) {
        formLleno = formu;
      } else {
        const datos = new FormData(event.currentTarget);
        datos.forEach((value, campo, veps) => {
          formLleno[campo] = value;
        });
        setData(formLleno);
      }

      if (props.verificarEmail && !verificado) {
        setConfirmarEmail(true);
        setEmail(formLleno.email);
      } else {
        console.log("Interesante...");

        setEnviando(true);
        Api("paginaregistro", "RegisterAfiliado", {
          data: formLleno,
          cuenta: params.cuenta,
          afiliado: params.ide,
          idFormulario: idFormulario,
        })
          .then((ress) => {
            if (ress.success) {
              window.location =
                "https://" +
                props.dominio +
                `?user=${ress.idusuario}&tt=${ress.token}`;
            } else {
              setErrorForm(true);
              setTextoError(ress.msg);
              setTimeout(() => {
                setErrorForm(false);
              }, 3000);
              setEnviando(false);
            }
          })
          .catch((e) => {
            setEnviando(false);
            console.log("houston");
          });
      }
    } catch (error) {
      console.log(error);
      setEnviando(false);
    }
  }

  return (
    <>
      <Mensaje errorForm={errorForm} textoError={textoError} />
      {modalConfirmarEmail && (
        <ModalVerificarEmail
          cuenta={params.cuenta}
          logo={props.logo}
          email={email}
          setConfirmarEmail={setConfirmarEmail}
          registrarme={registrarme}
          data={data}
        />
      )}
      <form key="oka" onSubmit={registrarme}>
        {campos.map((campo, index) => {
          return <Campo key={"ids" + index} campo={campo} index={index} />;
        })}
        {enviando ? (
          <ClipLoader loading={enviando} size={15} />
        ) : (
          <button disabled={enviando} style={{ marginTop: 20 }}>
            {textoBoton}
          </button>
        )}
      </form>
    </>
  );
}

export default Formulario;
