import axios from "axios";

// desarrollo

// const config = {
//   URI: "http://localhost:5000/api/public",
// };

// producción
const config = {
  URI: "https://m5d4mjeukj.execute-api.us-east-1.amazonaws.com/prod/api/public",
};

const instance = axios.create({
  baseURL: config.URI,
  headers: {
    "Content-Type": "application/json",
  },
});

function dataxx() {
  function numeroRandom100to150() {
    return Math.floor(Math.random() * (450 - 100 + 1)) + 100;
  }
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrs+tuv/wxyz0123456789";
  for (var i = 0; i < numeroRandom100to150(); i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}

const Api = async function (tipoconsulta, type, filtros) {
  return new Promise(async (resp, err) => {
    // El acción en GET representa el PROJECT
    var daxxt = new Date();
    var data = await window.x898(instance, "", type, filtros, {}, daxxt);

    instance
      .post(tipoconsulta, {
        a: dataxx() + "=",
        b: dataxx() + "=",
        c: dataxx() + "=",
        d: dataxx(),
        f: dataxx() + "=",
        g: dataxx() + "=",
        h: data,
        i: dataxx() + "=",
        j: dataxx() + "=",
        k: dataxx(),
        z: daxxt,
      })
      .then(function (response) {
        console.log(response.data);
        resp(response.data);
      })
      .catch(function (error) {
        console.log(error);
        err(error);
      });
  });
};

const Auth = async function (tipo, datos) {
  return instance.post(tipo, datos);
};

export { Api, Auth };
