import React from "react";
import { Api } from "../config/Api";

function ModalVerificarEmail(props) {
  const [codigoEnviado, setCodigoEnviado] = React.useState(false);
  const [codigo, setCodigo] = React.useState(0);
  const [codigoErroneo, setCodigoErroneo] = React.useState(false);

  function EnviarCodigo() {
    setCodigoEnviado(true);
    Api("paginaregistro", "EnviarCodigoEmail", {
      email: props.email,
      cuenta: props.cuenta,
    }).then((res) => {
      console.log("Código enviado");
    });
  }

  function VerificarCodigo() {
    Api("paginaregistro", "verificarCodigoEmail", {
      codigo: codigo,
      email: props.email,
    }).then((es) => {
      console.log(es);
      if (es.ress === 82584) {
        props.registrarme(null, props.data, true);
        props.setConfirmarEmail(false);
      } else {
        setCodigoErroneo(true);
        setTimeout(() => {
          setCodigoErroneo(false);
        }, 2000);
      }
    });
  }

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        magin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
        top: 0,
        left: 0,
      }}
    >
      {codigoEnviado ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img width={80} src={props.logo} alt="logo" />
          {codigoErroneo && (
            <span style={{ color: "red" }}>Codigo ERRONEO</span>
          )}
          <strong>Verificar EMAIL</strong>
          <span style={{ fontWeight: 300 }}>Ingresar el código recibido</span>
          <input
            style={{
              width: 200,
              height: 40,
              textAlign: "center",
              fontSize: 30,
              outline: "none",
            }}
            onChange={(e) => setCodigo(e.target.value)}
            type="number"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button className="boton" onClick={VerificarCodigo}>
              Verificar
            </button>
            <span onClick={() => props.setConfirmarEmail(false)}>Cancelar</span>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img width={80} src={props.logo} alt="logo" />
          <strong>Verificar EMAIL</strong>
          <span style={{ fontWeight: 300 }}>
            Debemos verificar que el email sea correcto
          </span>
          <span
            style={{
              marginTop: 5,
              padding: 10,
              background: "rgb(242 242 242)",
              borderRadius: 5,
            }}
          >
            {props.email}
          </span>
          <span
            style={{
              fontWeight: 300,
              fontSize: 12,
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            Enviaremos un codigo de verificación a este email
          </span>
          <div>
            <button className="boton" onClick={() => EnviarCodigo()}>
              Enviar Código
            </button>
            <button
              className="boton"
              onClick={() => props.setConfirmarEmail(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ModalVerificarEmail;
